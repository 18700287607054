<template>
  <transition-group name="list" tag="div">
    <div v-for="(item, index) in value" :key="item['id']" class="d-flex flex-wrap">
      <v-autocomplete
        v-model="item.field"
        outlined
        class="filter-item"
        :items="availableFields"
        :loading="loading"
        label="Conditional Field"
        :rules="[rules.required]"
        editable
        dense
        @change="changeValue(index)"
      />
      <v-autocomplete
        v-model="item.compare"
        outlined
        class="filter-item"
        :disabled="!item.field"
        :items="compareOptions"
        :rules="[rules.required]"
        :loading="loading"
        label="Available Tag"
        editable
        dense
        @change="changeValue(index)"
      />
      <v-textarea
        outlined
        rows="1"
        auto_grow
        clearable
        dense
        class="filter-item"
        :disabled="!item.field || !item.compare"
        v-model="item.value"
        :rules="[rules.required]"
        label="Value"
        hint="Ctrl + Enter to add filter"
        @change="changeValue(index)"
      />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :loading="loading" x-small fab class="red lighten-1 mx-1" @click="$emit('remove', index)">
           <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>Remove Filter</span>
      </v-tooltip>
    </div>
  </transition-group>
</template>

<script>
  export default {
    name: 'MinFilterBox',

    props: [
      'availableFields',
      'loading',
      'rules',
      'minCompareOptions',
      'value'
    ],

    data() {
      return {
        compareOptions: [
          ...this.minCompareOptions,
          {
            text: 'LIKE',
            value: 'like'
          },
          {
            text: 'Not LIKE',
            value: 'nlike'
          },
        ],
        filterValid: true,
        compareMappings: {
          gte: ' >= ',
          lte: ' <= ',
          eq: ' = ',
          neq: ' != ',
          like: 'LIKE',
          nlike: 'NOT LIKE',
          in: ' IN ',
          nin: ' NOT IN ',
        },
      }
    },
    methods: {
      callable (index) {
        return this.value[index].field && this.value[index].compare && this.value[index].value
      },
      changeValue(index) {
        if (!this.callable(index)) {
          return
        }
        const filter = this.value[index]
        let value = filter.value
        if (['like', 'nlike'].includes(filter.compare)) {
          value = "%" + value + "%"
        }
        this.value[index].cond = `\`${filter.field}\` ${this.compareMappings[filter.compare]} '${value}'`
        this.$emit('input', this.value)
      }
    }
  }
</script>
