<template>
  <v-card
    outlined
  >
    <v-card-title>
      Filters
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="!filterValid || loading" :loading="loading" x-small fab class="success mx-1" @click="addFilter">
           <v-icon >mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Filter</span>
      </v-tooltip>
      <v-tooltip
        v-if="filters.length"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn @click="clearFilter" v-on="on" icon fab x-small><v-icon>mdi-cancel</v-icon></v-btn>
        </template>
        <span>Clear All Filters</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <div class="filter-tag-list">
        <v-chip 
          v-for="(item, x) in filters"
          close
          outlined
          :key="x"
          @click:close="$emit('removeFilter', x)"
        >
          <span>{{displayFilter(item)}}</span>
        </v-chip>
      </div>
      <v-form 
        ref="filterForm"
        v-model="filterValid"
      >
        <div class="filter-box">
          <v-autocomplete
            v-model="filter.field"
            outlined
            class="filter-item"
            :disabled="!table"
            :items="availableFields"
            :loading="loading"
            label="Conditional Field"
            :rules="[rules.required]"
            editable
            dense
          />
          <v-autocomplete
            v-model="filter.compare"
            outlined
            class="filter-item"
            :disabled="!filter.field"
            :items="compareOptions"
            :rules="[rules.required]"
            :loading="loading"
            label="Available Tag"
            editable
            dense
          />
          <v-textarea
            v-if="filter.compare != 'in' && filter.compare != 'nin'"
            outlined
            rows="1"
            auto_grow
            clearable
            dense
            class="filter-item"
            :disabled="!filter.field || !filter.compare"
            v-model="filter.value"
            :rules="[rules.required]"
            label="Value"
            hint="Ctrl + Enter to add filter"
            @keyup.ctrl.13="addFilter"
          />

          <v-sheet outlined v-else class="pa-2 filter-item">
            <div>
              <div class="d-flex">
                <v-autocomplete
                  v-model="filter.table1"
                  outlined
                  placeholder="Table"
                  label="Select a Table"
                  dense
                  class="mr-3 filter-item"
                  :items="tables"
                  :loading="loading"
                  @change="changeTable1"
                />
                <v-autocomplete
                  v-model="filter.lookupField"
                  outlined
                  :disabled="!filter.table1"
                  class="no-gutters mr-2 filter-item"
                  :items="tableFields1"
                  :loading="loading"
                  label="Look up Field"
                  :rules="[rules.required]"
                  editable
                  dense
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :loading="loading" x-small fab class="success mx-1" @click="add">
                     <v-icon >mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add New Filter</span>
                </v-tooltip>
              </div>
              <mini-filter-box
                v-model="filter.conditions"
                :loading="loading"
                :availableFields="tableFields1"
                :rules="rules"
                :minCompareOptions="minCompareOptions"
                @remove="remove"
              />
            </div>
          </v-sheet>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="false">
      <v-btn
        class="main"
        plain
        :disabled="!rawQuery()"
        @click="showQuery = !showQuery"
      >
        Show Raw Query
        <v-icon right>{{ showQuery ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="showQuery">
        <v-divider></v-divider>
        <v-card-text>
          {{rawQuery()}}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import MiniFilterBox from './MiniFilterBox'

  export default {
    name: 'FilterBox',

    components: { MiniFilterBox },

    props: [
      'filters',
      'tables',
      'table',
      'tableField',
      'availableFields',
      'loading',
      'rules',
      'compareOptions',
      'minCompareOptions',
      'changeTable'
    ],

    data() {
      return {
        filterValid: true,
        filter: {
          table1: '',
          field: '',
          compare: '',
          value: '',
          lookupField: '',
          conditions: []
        },
        showQuery: false,
        tableFields1: [], // table fields for compare IN
        compareMappings: {
          gte: ' >= ',
          lte: ' <= ',
          eq: ' = ',
          neq: ' != ',
          like: 'LIKE',
          nlike: 'NOT LIKE',
          in: ' IN ',
          nin: ' NOT IN ',
        },
      }
    },

    computed: {
      callable () {
        return !this.filterValid || this.loading || !this.tableField
      }
    },

    methods: {
      displayFilter(filter) {
        let value = filter.value
        if (['like', 'nlike'].includes(filter.compare)) {
          value = "%" + value + "%"
        }
        let _filter = ` \`${filter.field}\` ${this.compareMappings[filter.compare]}`
        if (['in', 'nin'].includes(filter.compare)) {
          value = `(SELECT \`${filter.lookupField}\` FROM ${filter.table1}`
          if (filter.conditions) {
            const conds = []
            filter.conditions.forEach(condition => { 
              if (condition.cond) {
                conds.push(condition.cond)
              }
            })
            if (conds.length) {
              value += ` WHERE ${conds.join(' AND ')}`
            }
          }
          _filter += ` ${value})`
        } else {
          _filter += ` '${value}'`
        }
        return _filter
      },
      addFilter() {
        this.$emit('addFilter', this.filter)
        this.filter.conditions = []
      },
      clearFilter() {
        this.$emit('clearFilter')
        this.filter.field = this.filter.compare = this.filter.value = ''
        this.filter.conditions = []
        this.$refs.filterForm.reset()
      },
      add () {
        const id = this.filter.conditions[this.filter.conditions.length-1]?.id || 0
        this.filter.conditions.push({id: id+1, cond: ''})
      },
      remove (index) {
        this.$emit('remove')
        this.filter.conditions.splice(index, 1)
        this.addFilter()
      },
      async changeTable1 () {
        this.tableFields1 = await this.changeTable(this.filter.table1)
      },
      rawQuery () {
        let _query = ''
        if (!this.table || !this.tableField) {
          _query =  ''
        } else {

        }
        return _query
      }
    }
  }
</script>

<style lang="scss">
.filter-box {
  display: flex;
  flex-wrap: wrap;

  .filter-item {
    margin-right: .4rem;
    margin-bottom: .4rem;
  }
}
</style>